import { CSSPropertiesDict } from "../../helpers/types";

const container: React.CSSProperties = {
  width: "100%",
};

const style: CSSPropertiesDict = {
  container,
};

export default style;
