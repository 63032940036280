import { createTheme } from "@mui/material";
import baseTheme from "./base";
import lightPalette from "./palettes/light";

const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    primary: lightPalette.primary,
    mode: "light",
    text: lightPalette.text,
    background: lightPalette.background,
  },
  components: {
    ...baseTheme.components,
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&": {
            color: lightPalette.text.primary,
          },
          "&.Mui-focused": {
            color: lightPalette.secondary.main,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected:hover": {
            backgroundColor: lightPalette.secondary.main,
          },
          "&:hover": {
            backgroundColor: lightPalette.secondary.main,
            color: lightPalette.secondary.contrastText,
          },
        },
      },
    },
  },
});

export default lightTheme;
