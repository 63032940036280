import fonts from "./helpers/fonts";
import { CSSPropertiesDict } from "./helpers/types";

const defaultStyle: React.CSSProperties = {
  fontFamily: fonts.default,
};

const appStyle: CSSPropertiesDict = {
  defaultStyle,
};

const indexStyle: CSSPropertiesDict = {
  body: {
    margin: "0",
    padding: "10px",
  },
};

const indexStyleMd: CSSPropertiesDict = {
  ...indexStyle,
};

const indexStyleLtMd: CSSPropertiesDict = {
  body: {
    ...indexStyle.body,
    padding: "20px 0",
  },
};

const style = { appStyle, indexStyleMd, indexStyleLtMd };

export default style;
