import { Trans } from "@lingui/macro";
import logo from "../../../assets/pictures/technology-logo/celery-logo.png";
import pythonLogo from "../../../assets/pictures/technology-logo/python-logo.png";
import rabbitmqLogo from "../../../assets/pictures/technology-logo/rabbitmq-logo.png";
import redisLogo from "../../../assets/pictures/technology-logo/redis-logo.png";
import { TechnologyCardProps } from "../../../components/technology-card/interfaces";

const CELERY: TechnologyCardProps = {
  title: "Celery",
  order: 9,
  logo,
  subTitle: "Python asynchronous job queue",
  detailLogos: [pythonLogo, redisLogo, rabbitmqLogo],
  latestUsedVersion: "5.4",
  url: "docs.celeryq.dev",
  favorite: false,
  description: (
    <Trans>
      <p>
        <strong>Celery</strong> is an asynchronous task queue/job queue based on
        distributed message passing.
      </p>
      <h3>How we use it</h3>
      <p>
        When implementing asynchronous jobs, we use Celery with RabbitMQ as the
        message broker. Redis is also employed to track job results and
        progress. Celery works seamlessly with Python frameworks, especially
        Django and FastAPI.
        <br />
        If real-time job tracking is required, Websockets are implemented.
        Celery, along with Redis and RabbitMQ, is typically containerized using
        Docker.
      </p>
      <h3>Why we use it</h3>
      <p>
        <ul>
          <li>
            <strong>Python-based</strong>: Perfectly fits our Python-centric
            stack.
          </li>
          <li>
            <strong>Good Django Integration</strong>: Excellent compatibility
            with Django.
          </li>
          <li>
            <strong>Easy Implementation and Maintenance</strong>: Simplifies the
            setup and management of asynchronous jobs.
          </li>
          <li>
            <strong>Concurrency Handling</strong>: Efficiently manages
            concurrent tasks.
          </li>
        </ul>
      </p>
    </Trans>
  ),
};

export default CELERY;
