import CopyrightIcon from "@mui/icons-material/Copyright";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import style from "../style";
import Contact from "./contact";
import BaseContainer from "../container";

const CREATION_YEAR = 2024;
const currentYear: number = new Date(Date.now()).getFullYear();

const copyRightYear: number | string =
  CREATION_YEAR == currentYear ? CREATION_YEAR : (
    `${CREATION_YEAR} - ${currentYear}`
  );

const version = process.env.REACT_APP_VERSION || "Development mode";

const BaseFooter = () => {
  const theme = useTheme();
  const isLtMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      className="BaseFooter"
      style={isLtMd ? style.footerContainerLtMd : style.footerContainerMd}
    >
      <BaseContainer>
        <Contact />
      </BaseContainer>

      <Stack direction="row" spacing={1} sx={style.copyright}>
        <span>Northenware BV</span>
        <CopyrightIcon />
        <span>{copyRightYear}</span>
        <span style={style.version}>{version}</span>
      </Stack>
    </div>
  );
};

export default BaseFooter;
