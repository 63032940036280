import { Button } from "@mui/material";
import { CSSProperties, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

const NavigationButton: React.FC<{
  path: string;
  label: string | ReactNode;
  icon: ReactNode;
  fullWidth: boolean;
}> = ({ path, label, icon, fullWidth }) => {
  const location = useLocation();
  const currentPath = location.pathname.replace(/^\//, "");

  const variant = path === currentPath ? "contained" : "outlined";

  const style: CSSProperties = fullWidth ? { width: "100%" } : {};

  return (
    <div className="NavigationButton" style={style}>
      <Link to={path}>
        <Button variant={variant} startIcon={icon} sx={style}>
          {label}
        </Button>
      </Link>
    </div>
  );
};

export default NavigationButton;
