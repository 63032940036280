import { i18n } from "@lingui/core";
import { LOCAL_STORAGE_KEYS, SUPPORTED_LOCALES } from "./consts";

/**
 * Dynamically loads and activates the given locale.
 *
 * @param locale - The language code (e.g., 'en', 'fr', 'nl') to activate.
 * @returns Promise<void> - A promise that resolves when the locale messages are
 * loaded and activated.
 */
export const dynamicActivate = async (
  locale: string,
  storeInLocalStorage: boolean = true,
) => {
  const { messages } = await import(`../locales/${locale}/messages`);

  i18n.load(locale, messages);
  i18n.activate(locale);

  if (storeInLocalStorage) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALE, locale);
  }
};

/**
 * Retrieves the best matching locale from the browser or falls back to default.
 *
 * @param defaultLocale - The default locale to fall back to.
 * @returns string - The detected, validated or default locale.
 */
export const getPreferredLocale = (defaultLocale: string): string => {
  const supportedLocales = Object.keys(SUPPORTED_LOCALES);

  const storedLocale = localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALE);
  if (storedLocale && supportedLocales.includes(storedLocale)) {
    return storedLocale;
  }

  const browserLocale = navigator.language || navigator.languages?.[0];
  if (browserLocale) {
    const langCode = browserLocale.split("-")[0];

    if (supportedLocales.includes(langCode)) {
      return langCode;
    }
  }

  return defaultLocale;
};
