import { Outlet } from "react-router-dom";
import BaseFooter from "./footer";
import BaseHeader from "./header";

const BaseComponent = () => {
  return (
    <div className="BaseComponent">
      <header>
        <BaseHeader />
      </header>

      <body>
        <Outlet />
      </body>

      <footer>
        <BaseFooter />
      </footer>
    </div>
  );
};

export default BaseComponent;
