import { Trans } from "@lingui/macro";
import logo from "../../../assets/pictures/technology-logo/gitlab-logo.png";
import gitLogo from "../../../assets/pictures/technology-logo/git-logo.png";
import { TechnologyCardProps } from "../../../components/technology-card/interfaces";

const GITLAB_PIPELINES: TechnologyCardProps = {
  title: "GitLab Pipelines",
  order: 6,
  logo,
  subTitle:
    "top-level component of continuous integration, delivery and deployment",
  detailLogos: [gitLogo],
  latestUsedVersion: "17.4",
  url: "docs.gitlab.com/ee/ci/pipelines",
  favorite: true,
  description: (
    <Trans>
      <p>
        <strong>GitLab Pipelines</strong> provide a robust framework for CI/CD
        automation, streamlining the development process.
      </p>
      <h3>How we use it</h3>
      <p>
        We use pipelines with GitLab runners to automatically build Docker
        images. This often involves transforming Node containers into Nginx
        containers, and also preparing Python containers ready to be served.
        <br />
        During the build process, we pull the container in another stage and run
        tests on it. We can even automate the deployment by pulling the latest
        or the new stable version after it's been pushed to the container
        registry.
        <br />
        This automation removes the entire release procedure from the developers
        if the image is correctly built and tested.
      </p>
      <h3>Why we use it</h3>
      <p>
        <ul>
          <li>
            <strong>Easy CI/CD Automation</strong>: Streamlines the continuous
            integration and deployment processes.
          </li>
          <li>
            <strong>Integrated with GitLab</strong>: Works seamlessly with our
            code repository and project management tool.
          </li>
          <li>
            <strong>Self-hosted Runners</strong>: Can run on our infrastructure,
            providing flexibility and control.
          </li>
          <li>
            <strong>Artifact Storage</strong>: Stores build artifacts in the
            GitLab registry, ensuring easy access and management.
          </li>
        </ul>
      </p>
    </Trans>
  ),
};

export default GITLAB_PIPELINES;
