import { TechnologyCardProps } from "../../../components/technology-card/interfaces";
import ANGULAR from "./angular";
import BITBUCKET_PIPELINES from "./bitbucket-pipeline";
import CELERY from "./celery";
import DJANGO from "./django";
import DOCKER from "./docker";
import FASTAPI from "./fastapi";
import GITLAB_PIPELINES from "./gitlab-pipeline";
import GRAPHQL from "./graphql";
import MYSQL from "./mysql";
import NGINX from "./nginx";
import POSTGRESQL from "./postgres";
import PYTHON from "./python";
import REACT from "./react";
import UBUNTU from "./ubuntu";

export const DATA: TechnologyCardProps[] = [
  DJANGO,
  REACT,
  DOCKER,
  POSTGRESQL,
  GRAPHQL,
  NGINX,
  UBUNTU,
  GITLAB_PIPELINES,
  FASTAPI,
  ANGULAR,
  CELERY,
  PYTHON,
  MYSQL,
  BITBUCKET_PIPELINES,
];
