import fonts from "../../../helpers/fonts";
import { CSSPropertiesDict } from "../../../helpers/types";
import { NORD_COLORS } from "../../../themes/palettes/colors";

const button: React.CSSProperties = {
  width: "100%",
};

const details: React.CSSProperties = {
  fontFamily: fonts.default,
  textAlign: "start",
};

const logo: React.CSSProperties = {
  width: "15em",
};
const title: React.CSSProperties = {
  fontFamily: fonts.title,
  textAlign: "start",
  textTransform: "uppercase",
};

const style: CSSPropertiesDict = {
  button,
  details,
  logo,
  title,
};

export default style;
