import { Trans } from "@lingui/macro";
import { Slide } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import React from "react";
import {
  DEFAULT_ALERT_ANCHOR_ORIGIN,
  DEFAULT_ALERT_HIDE_DURATION,
} from "../../helpers/consts";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CopyToClipboardAlert: React.FC<{
  showAlert: boolean;
  setShowAlert: (showAlert: boolean) => void;
}> = ({ showAlert, setShowAlert }) => {
  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ): void => {
    if (reason === "clickaway") {
      return;
    }

    setShowAlert(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={DEFAULT_ALERT_ANCHOR_ORIGIN}
        autoHideDuration={DEFAULT_ALERT_HIDE_DURATION}
        onClose={handleClose}
        open={showAlert}
        TransitionComponent={Slide}
      >
        <Alert
          icon={<ContentCopyIcon fontSize="inherit" />}
          onClose={handleClose}
          variant="filled"
        >
          <Trans>VAT number has been copied to your clipboard.</Trans>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CopyToClipboardAlert;
