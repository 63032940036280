import { Trans } from "@lingui/macro";
import logo from "../../../assets/pictures/technology-logo/bitbucket-logo.png";
import gitLogo from "../../../assets/pictures/technology-logo/git-logo.png";

import { TechnologyCardProps } from "../../../components/technology-card/interfaces";

const BITBUCKET_PIPELINES: TechnologyCardProps = {
  title: "BitBucket Pipelines",
  order: 12,
  logo,
  subTitle:
    "top-level component of continuous integration, delivery and deployment",
  detailLogos: [gitLogo],
  latestUsedVersion: "8.19",
  url: "support.atlassian.com/bitbucket-cloud/docs/get-started-with-bitbucket-pipelines",
  favorite: false,
  description: (
    <Trans>
      <p>
        <strong>Bitbucket Pipelines</strong> provide an efficient CI/CD
        automation tool integrated within Bitbucket.
      </p>
      <h3>How we use it</h3>
      <p>
        Exactly like GitLab Pipelines, we set Bitbucket Pipelines at the project
        level and run self-hosted runners on our own servers. The integration
        with Bitbucket simplifies usage, especially when working within
        Bitbucket-based projects.
        <br />
        Despite this, we usually prefer GitLab for its features.
      </p>
      <p>
        <h3>Why we use it</h3>
        <ul>
          <li>
            <strong>For Bitbucket-based Projects</strong>: Ideal for projects
            hosted on Bitbucket.
          </li>
          <li>
            <strong>CI/CD Automation</strong>: Streamlines continuous
            integration and deployment processes.
          </li>
          <li>
            <strong>Good Integration</strong>: Seamlessly integrates with both
            Bitbucket and Jira.
          </li>
        </ul>
      </p>
    </Trans>
  ),
};

export default BITBUCKET_PIPELINES;
