import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import BaseComponent from "../components/base";
import HomePage from "../pages/home";
import DetailPage from "../pages/technical";
import { DynamicDict } from "./types";

export const ROUTES: DynamicDict = {
  HOME: "",
  TECHNICAL: "technical",
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<BaseComponent />}>
      <Route path={ROUTES.HOME} element={<HomePage />} />
      <Route path={ROUTES.TECHNICAL} element={<DetailPage />} />
    </Route>,
  ),
);

export default router;
