import { Trans } from "@lingui/macro";
import logo from "../../../assets/pictures/technology-logo/postgresql-logo.png";
import { TechnologyCardProps } from "../../../components/technology-card/interfaces";

const POSTGRESQL: TechnologyCardProps = {
  title: "Postgresql",
  order: 3,
  logo,
  subTitle: "Relational database management system",
  detailLogos: [],
  latestUsedVersion: "14",
  url: "www.postgresql.org",
  favorite: true,
  description: (
    <Trans>
      <p>
        <strong>PostgreSQL</strong> is a powerful, open-source object-relational
        database system known for its robustness and performance.
      </p>
      <h3>How we use it</h3>
      <p>
        We use PostgreSQL with each backend Django application when a database
        is required. It is a complete database framework. We strive to avoid
        direct database alterations as much as possible; instead, the
        application handles all changes through its ORM.
        <br />
        Additionally, the database runs in a Docker container, making
        replication easier.
      </p>
      <h3>Why we use it</h3>
      <p>
        <ul>
          <li>
            <strong>Recommended by Django</strong>: Seamless integration with
            Django applications.
          </li>
          <li>
            <strong>Good Performance</strong>: Reliable and efficient, even for
            large-scale applications.
          </li>
          <li>
            <strong>Fuzzy Search</strong>: Capabilities for advanced search
            functionalities.
          </li>
          <li>
            <strong>Excellent Integration</strong>: Works well with Python
            frameworks.
          </li>
          <li>
            <strong>Popular</strong>: SQL, the most widely-used database
            language, ensures a broad support network and resource availability.
          </li>
        </ul>
      </p>
    </Trans>
  ),
};

export default POSTGRESQL;
