import { Trans } from "@lingui/macro";
import BuildIcon from "@mui/icons-material/Build";
import CodeIcon from "@mui/icons-material/Code";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { Grid2, Paper, useMediaQuery, useTheme } from "@mui/material";
import pic1 from "../../assets/pictures/illustrations/application.jpg";
import pic2 from "../../assets/pictures/illustrations/application2.jpg";
import pic3 from "../../assets/pictures/illustrations/application3.jpg";
import pic4 from "../../assets/pictures/illustrations/coding.jpg";
import pic5 from "../../assets/pictures/illustrations/coding2.jpg";
import pic6 from "../../assets/pictures/illustrations/multi-device.jpg";
import BaseContainer from "../../components/base/container";
import LogoSeparator from "../../components/shared/logo-separator";
import style from "./style";

const HomePage = () => {
  const theme = useTheme();
  const isLtMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      className="HomePage"
      style={isLtMd ? style.containerLtMd : style.containerMd}
    >
      <BaseContainer>
        <h1 style={isLtMd ? style.titleLtMd : style.titleMd}>
          <Trans>What we do</Trans>
        </h1>

        <Paper style={style.paper}>
          <Grid2 container spacing={0}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <img
                src={pic2}
                alt="We create client-facing, internal or custom applications."
                style={style.illustration}
              />
            </Grid2>
            <Grid2 size={{ xs: 0, md: 6 }}>
              <img
                src={pic1}
                alt="We ensure to make the application the most user-friendly as possible"
                style={style.illustration}
              />
            </Grid2>

            <Grid2 size={12} sx={style.descriptionGridItem}>
              <CodeIcon style={style.descriptionIcon} />
            </Grid2>
            <Grid2 size={12}>
              <Trans>
                <div style={style.description}>
                  Whether you need a website, an internal tool, or a custom
                  platform, we build solutions that are fast, reliable, and
                  tailored to your needs.
                </div>
              </Trans>
            </Grid2>
          </Grid2>
        </Paper>

        <LogoSeparator />

        <Paper style={style.paper}>
          <Grid2 container spacing={0}>
            <Grid2 size={{ xs: 0, md: 6 }}>
              <img
                src={pic4}
                alt="We work on the latest software development frameworks."
                style={style.illustration}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <img
                src={pic6}
                alt="We make multi-device application which are mobile phone friendly."
                style={style.illustration}
              />
            </Grid2>

            <Grid2 size={12} sx={style.descriptionGridItem}>
              <BuildIcon style={style.descriptionIcon} />
            </Grid2>
            <Grid2 size={12}>
              <Trans>
                <div style={style.description}>
                  We specialize in creating custom web applications that help
                  businesses like yours streamline operations and improve
                  customer engagement.
                </div>
              </Trans>
            </Grid2>
          </Grid2>
        </Paper>

        <LogoSeparator />

        <Paper style={style.paper}>
          <Grid2 container spacing={0}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <img
                src={pic3}
                alt="We suit the products to your needs."
                style={style.illustration}
              />
            </Grid2>
            <Grid2 size={{ xs: 0, md: 6 }}>
              <img
                src={pic5}
                alt="We make the product secure and optimized."
                style={style.illustration}
              />
            </Grid2>

            <Grid2 size={12} sx={style.descriptionGridItem}>
              <SettingsSuggestIcon style={style.descriptionIcon} />
            </Grid2>
            <Grid2 size={12}>
              <Trans>
                <div style={style.description}>
                  Using modern technologies, we ensure your web application is
                  secure, scalable, and easy to maintain, so you can focus on
                  growing your business.
                </div>
              </Trans>
            </Grid2>
          </Grid2>
        </Paper>
      </BaseContainer>
    </div>
  );
};

export default HomePage;
