import { ThemeContext } from "@emotion/react";
import { useContext } from "react";
import darkLogo from "../../assets/pictures/logo/dark/brand-logo.png";
import lightLogo from "../../assets/pictures/logo/light/brand-logo.png";

const style: React.CSSProperties = {
  margin: "3px 0",
  width: "70px",
};

const LogoSeparator = () => {
  const { darkMode } = useContext<any>(ThemeContext);

  return (
    <div className="LogoSeparator">
      <img
        src={darkMode ? darkLogo : lightLogo}
        alt="Northenware logo separator"
        style={style}
      />
    </div>
  );
};

export default LogoSeparator;
