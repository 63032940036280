import React from "react";
import fonts from "../../helpers/fonts";
import { CSSPropertiesDict } from "../../helpers/types";

const detailLogo: React.CSSProperties = {
  height: "20px",
};

const headSection: React.CSSProperties = {
  cursor: "pointer",
};

const linkButton: React.CSSProperties = {
  width: "100%",
};

const logo: React.CSSProperties = {
  maxHeight: "200px",
};

const logoWrapper: React.CSSProperties = {
  textAlign: "center",
  width: "100%",
};

const paper: React.CSSProperties = {
  fontFamily: fonts.default,
  textAlign: "justify",
  margin: "1em 0",
  padding: "1em",
};

const subTitle: React.CSSProperties = {
  textAlign: "start",
  textTransform: "uppercase",
};

const title: React.CSSProperties = {
  fontFamily: fonts.title,
  margin: "0",
  textTransform: "uppercase",
};

const style: CSSPropertiesDict = {
  detailLogo,
  headSection,
  linkButton,
  logo,
  logoWrapper,
  paper,
  subTitle,
  title,
};

export default style;
