import { Trans } from "@lingui/macro";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { Key } from "react";
import BaseContainer from "../../components/base/container";
import TechnologyDetailCard from "../../components/technology-card";
import { TechnologyCardProps } from "../../components/technology-card/interfaces";
import { DATA } from "./data";
import style from "./style";

const DetailPage = () => {
  const theme = useTheme();
  const isLtMd = useMediaQuery(theme.breakpoints.down("md"));

  const { sortedFavoriteData, sortedNotFavoriteData } = DATA.reduce<{
    sortedFavoriteData: TechnologyCardProps[];
    sortedNotFavoriteData: TechnologyCardProps[];
  }>(
    (
      accumulator: {
        sortedFavoriteData: TechnologyCardProps[];
        sortedNotFavoriteData: TechnologyCardProps[];
      },
      technology: TechnologyCardProps,
    ) => {
      if (technology.favorite) {
        accumulator.sortedFavoriteData.push(technology);
      } else {
        accumulator.sortedNotFavoriteData.push(technology);
      }
      return accumulator;
    },
    { sortedFavoriteData: [], sortedNotFavoriteData: [] },
  );

  sortedFavoriteData.sort((a, b) => a.order - b.order);
  sortedNotFavoriteData.sort((a, b) => a.order - b.order);

  return (
    <div className="DetailPage">
      <BaseContainer>
        <h1 style={isLtMd ? style.titleLtMd : style.titleMd}>
          <Trans>What we use</Trans>
        </h1>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          sx={style.favoriteStackTitle}
        >
          <Trans>Our favorite stack</Trans>
          <FavoriteIcon sx={style.favoriteIcon} />
        </Stack>
        <section style={style.favoritesSection}>
          {sortedFavoriteData.map(technologyProps => (
            <TechnologyDetailCard
              key={technologyProps.title as Key}
              technologyProps={technologyProps}
            />
          ))}
        </section>

        <section style={style.notFavoritesSection}>
          {sortedNotFavoriteData.map(technologyProps => (
            <TechnologyDetailCard
              key={technologyProps.title as Key}
              technologyProps={technologyProps}
            />
          ))}
        </section>
      </BaseContainer>
    </div>
  );
};

export default DetailPage;
