import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import style from "./style";
import baseTheme from "./themes/base";

const applyMediaQueryStyles = () => {
  Object.assign(
    document.body.style,
    window.innerWidth < baseTheme.breakpoints.values.md ?
      style.indexStyleLtMd.body
    : style.indexStyleMd.body,
  );
};

applyMediaQueryStyles();
window.addEventListener("resize", applyMediaQueryStyles);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

reportWebVitals();
