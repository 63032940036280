import { ThemeContext } from "@emotion/react";
import { Trans } from "@lingui/macro";
import CommitIcon from "@mui/icons-material/Commit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LaunchIcon from "@mui/icons-material/Launch";
import { Chip, Collapse, Grid2, IconButton, Paper, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import ExpandMoreButton from "../expand-more-button.tsx";
import { TechnologyCardProps } from "./interfaces";
import style from "./style";
import { LatestUsedVersionSpan } from "./styled-components";

const TechnologyDetailCard: React.FC<{
  technologyProps: TechnologyCardProps;
}> = ({ technologyProps }) => {
  const { darkMode } = useContext<any>(ThemeContext);
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleExpandOnClick = (): void => setExpanded(!expanded);

  return (
    <div id={technologyProps.title.toLowerCase()} className="technology-card">
      <Paper sx={style.paper}>
        <section onClick={handleExpandOnClick} style={style.headSection}>
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <h2 style={style.title}>{technologyProps.title}</h2>
            <Stack direction="row" alignItems="start">
              <a href={"https://" + technologyProps.url} target="_blank">
                <IconButton color="secondary">
                  <LaunchIcon />
                </IconButton>
              </a>
              <ExpandMoreButton
                expand={expanded}
                color="inherit"
                onClick={handleExpandOnClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMoreButton>
            </Stack>
          </Stack>

          <Grid2
            container
            spacing={{ xs: 1, md: 2 }}
            justifyContent="space-between"
          >
            <Grid2 size={{ xs: 12, md: 9 }}>
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 0, md: 1 }}
              >
                <div style={style.subTitle}>{technologyProps.subTitle}</div>

                <Stack direction="row" spacing={1}>
                  {technologyProps.detailLogos.map(detailLogo => (
                    <img src={detailLogo} style={style.detailLogo} />
                  ))}
                </Stack>
              </Stack>
            </Grid2>

            <Grid2 size={{ xs: 12, md: 3 }}>
              <Stack direction="row" spacing={1} justifyContent="end">
                {technologyProps.favorite && (
                  <Chip
                    icon={<FavoriteIcon />}
                    size="small"
                    color="success"
                    label={<Trans>Favorite</Trans>}
                    variant={darkMode ? "outlined" : "filled"}
                  />
                )}
                <Chip
                  icon={<CommitIcon />}
                  size="small"
                  color="primary"
                  label={
                    <>
                      <Trans>Last version used</Trans>
                      <LatestUsedVersionSpan>
                        {technologyProps.latestUsedVersion}
                      </LatestUsedVersionSpan>
                    </>
                  }
                  variant="outlined"
                />
              </Stack>
            </Grid2>
          </Grid2>
        </section>

        <Collapse in={expanded}>
          <hr />
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, md: 3 }}>
              <div style={style.logoWrapper}>
                <img
                  src={technologyProps.logo}
                  alt={(technologyProps.title as string) + "-logo"}
                  style={style.logo}
                />
              </div>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 9 }}>
              <div style={style.description}>{technologyProps.description}</div>
            </Grid2>
          </Grid2>
        </Collapse>
      </Paper>
    </div>
  );
};

export default TechnologyDetailCard;
