import React from "react";
import fonts from "../../helpers/fonts";
import { CSSPropertiesDict } from "../../helpers/types";
import { NORD_COLORS } from "../../themes/palettes/colors";

const favoriteIcon: React.CSSProperties = { fontSize: "inherit", margin: "0" };

const favoriteStackTitle: React.CSSProperties = {
  color: NORD_COLORS.NORD14,
  fontSize: "1em",
  fontFamily: fonts.default,
  margin: "0",
  textAlign: "right",
  textTransform: "uppercase",
};

const section: React.CSSProperties = {
  borderRadius: "5px",
  borderStyle: "solid",
  borderWidth: "1px",
  padding: "0 5px",
};

const favoritesSection: React.CSSProperties = {
  ...section,
  borderColor: NORD_COLORS.NORD14,
  marginBottom: "10px",
};

const notFavoritesSection: React.CSSProperties = {
  ...section,
  borderStyle: "none",
};

const title: React.CSSProperties = {
  fontFamily: fonts.title,
  textTransform: "uppercase",
};

const titleLtMd: React.CSSProperties = {
  ...title,
  textAlign: "center",
};

const titleMd: React.CSSProperties = {
  ...title,
  textAlign: "left",
};

const style: CSSPropertiesDict = {
  favoriteIcon,
  favoriteStackTitle,
  favoritesSection,
  notFavoritesSection,
  titleLtMd,
  titleMd,
};

export default style;
