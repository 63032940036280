import { styled, Theme } from "@mui/material";
import fonts from "../../helpers/fonts";

export const LatestUsedVersionSpan: React.FC<React.HTMLAttributes<HTMLSpanElement>> =
  styled("span")(({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    fontFamily: fonts.monospace,
    fontWeight: "bold",
    marginLeft: "2px",
    padding: "0 2px",
  }));
