import fonts from "../../helpers/fonts";
import { CSSPropertiesDict } from "../../helpers/types";

const container: React.CSSProperties = {
  textAlign: "center",
};

const containerLtMd: React.CSSProperties = {
  ...container,
  padding: "0em",
  paddingBottom: "1em",
};

const containerMd: React.CSSProperties = {
  ...container,
};

const description: React.CSSProperties = {
  padding: "0 1em",
  fontFamily: fonts.default,
  fontSize: "1.5em",
  textAlign: "justify",
};

const descriptionGridItem: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const descriptionIcon: React.CSSProperties = {
  height: "100%",
};

const illustration: React.CSSProperties = {
  aspectRatio: "30/20",
  objectFit: "cover",
  width: "100%",
};

const paper: React.CSSProperties = {
  paddingBottom: "1em",
};

const title: React.CSSProperties = {
  fontFamily: fonts.title,
  textTransform: "uppercase",
};

const titleLtMd: React.CSSProperties = {
  ...title,
  textAlign: "center",
};

const titleMd: React.CSSProperties = {
  ...title,
  textAlign: "left",
};

const style: CSSPropertiesDict = {
  containerMd,
  containerLtMd,
  description,
  descriptionGridItem,
  descriptionIcon,
  illustration,
  paper,
  titleLtMd,
  titleMd,
};

export default style;
