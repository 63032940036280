import { ThemeContext } from "@emotion/react";
import { useMediaQuery, useTheme } from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";
import darkLogo from "../../../assets/pictures/logo/dark/brand.png";
import lightLogo from "../../../assets/pictures/logo/light/brand.png";
import { ROUTES } from "../../../helpers/routes";
import BaseContainer from "../container";
import style from "../style";
import NavigationBar from "./navigation";

const BaseHeader = () => {
  const { darkMode } = useContext<object>(ThemeContext) as {
    darkMode: boolean;
  };

  const theme = useTheme();
  const isLtMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      className="BaseHeader"
      style={isLtMd ? style.headerContainerLtMd : style.headerContainerMd}
    >
      <BaseContainer>
        <Link to={ROUTES.HOME}>
          <div>
            <img
              src={darkMode ? darkLogo : lightLogo}
              alt="Northenware logo"
              style={isLtMd ? style.brandLtMd : style.brandMd}
            />
          </div>
        </Link>
      </BaseContainer>

      <NavigationBar />
    </div>
  );
};

export default BaseHeader;
